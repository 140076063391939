<template>
  <div class="node-editor">
    <div v-if="node && node.metrics && node.attributes" class="row mb-3">
      <div class="col-12">
        <div class="form-group">
          <h5>Seitentyp:</h5>
          <select @change="setPagetype(node.url, $event)" class="form-control">
            <option value="" disabled="disabled">Seitentyp auswählen</option>
            <option v-for="(pagetype, index) in pagetypes" :value="pagetype.id" :key="index">{{ pagetype.title }}</option>
          </select>
        </div>
        <hr>
      </div>
      <div :class="fullWidth ? 'col-12 mb-3' : 'col-md-6'">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="mb-3">Metriken:</h5>
            <ul class="list-group">
              <li v-for="(metric, index) in metrics" class="list-group-item" :key="'c' + index">
                <h6>{{ metric.title }}</h6>
                <input type="number"
                       :value="node.metrics[metric.id]"
                       @change="setMetric(node.url, metric, $event)"
                       placeholder="Metrik-Wert hinzufügen"
                       class="form-control"
                >
              </li>
            </ul>
          </div>
          <div class="card-footer">
            <h6>Neue Metrik erstellen</h6>
            <input type="text" v-model="newMetric.title" @change="createMetric" placeholder="Name der Metrik" class="form-control">
          </div>
        </div>
      </div>
      <div :class="fullWidth ? 'col-12 mb-3' : 'col-md-6'">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="mb-3">Attribute:</h5>
            <ul class="list-group">
              <li v-for="(attribute, index) in attributes" class="list-group-item" :key="'a' + index">
                <h6>{{ attribute.title }}</h6>
                <ul class="list-unstyled">
                  <li v-for="(value, index) in node.attributes[attribute.id]" class="mb-2" :key="'b' + index">
                    {{ value }}
                    <i @click="removeAttribute(node.url, attribute, index)"
                       class="fal fa-minus-circle text-danger float-right"></i>
                  </li>
                  <li>
                    <input type="text"
                           @change="addAttribute(node.url, attribute, $event)"
                           placeholder="Attribut-Wert hinzufügen"
                           class="form-control"
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="card-footer">
            <h6>Neues Attribut erstellen</h6>
            <input type="text" v-model="newAttribute.title" @change="createAttribute" placeholder="Name des Attributs" class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="small text-muted mb-0">Es ist ein Fehler aufgetreten.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodeEditor',
  components: {},
  directives: {},
  props: {
    node: Object,
    fullWidth: Boolean
  },
  data() {
    return {
      newMetric: {
        id: this.generateId(),
        title: ""
      },
      newAttribute: {
        id: this.generateId(),
        title: ""
      },
    }
  },
  computed: {
    pagetypes() {
      return this.project.settings.nodePagetypes || [];
    },
    metrics() {
      return this.project.settings.nodeMetrics || [];
    },
    attributes() {
      return this.project.settings.nodeAttributes || [];
    },
  },
  methods: {
    createAttribute() {
      if (!this.project.settings.nodeAttributes) {
        this.project.settings.nodeAttributes = [];
      }
      this.project.settings.nodeAttributes.push(this.newAttribute);
      this.newAttribute = {
        id: this.generateId(),
        title: ""
      };
      this.$store.dispatch('project/updateProjectByProp', {prop: 'settings', data: this.project.settings})
          .then(res => {
            console.debug(res);
          });
    },
    createMetric() {
      if (!this.project.settings.nodeMetrics) {
        this.project.settings.nodeMetrics = [];
      }
      this.project.settings.nodeMetrics.push(this.newMetric);
      this.newMetric = {
        id: this.generateId(),
        title: ""
      };
      this.$store.dispatch('project/updateProjectByProp', {prop: 'settings', data: this.project.settings})
          .then(res => {
            console.debug(res);
          });
    },
    setMetric(nodeUrl, metric, $event) {
      let index = this.project.nodeMetrics.findIndex(n => n.url === nodeUrl);
      if (index >= 0) {
        this.project.nodeMetrics[index][metric.id] = $event.target.value;
      } else {
        let metrics = this.project.settings.nodeMetrics.reduce((acc, n) => {
          acc[n.id] = 0;
          return acc;
        }, {url: nodeUrl});
        metrics[metric.id] = $event.target.value;
        this.project.nodeMetrics.push(metrics);
      }
      this.$store.dispatch("project/updateProjectByProp", {prop: "nodeMetrics", data: this.project.nodeMetrics});
    },
    addAttribute(nodeUrl, attribute, $event) {
      if ($event.target.value === "") return;
      let index = this.project.nodeAttributes.findIndex(n => n.url === nodeUrl);
      if (index >= 0) {
        this.project.nodeAttributes[index][attribute.id].push($event.target.value);
      } else {
        let attributes = this.project.settings.nodeAttributes.reduce((acc, n) => {
          acc[n.id] = [];
          return acc;
        }, {url: nodeUrl});
        attributes[attribute.id].push($event.target.value);
        this.project.nodeAttributes.push(attributes);
      }
      this.$store.dispatch("project/updateProjectByProp", {prop: "nodeAttributes", data: this.project.nodeAttributes});
      $event.target.value = "";
    },
    removeAttribute(nodeUrl, attribute, index) {
      let aIndex = this.project.nodeAttributes.findIndex(n => n.url === nodeUrl);
      if (aIndex >= 0) {
        this.project.nodeAttributes[aIndex][attribute.id].splice(index, 1);
      }
      this.$store.dispatch("project/updateProjectByProp", {prop: "nodeAttributes", data: this.project.nodeAttributes});
    },
    setPagetype(nodeUrl, $event) {
      this.node.pagetype = $event;
      let index = this.project.nodePagetypes.findIndex(n => n.url === nodeUrl);
      if (index >= 0) {
        this.project.nodePagetypes[index]['typeId'] = $event.target.value;
      } else {
        let pagetype = {
          url: nodeUrl,
          typeId: $event.target.value
        }
        this.project.nodePagetypes.push(pagetype);
      }
      this.$store.dispatch("project/updateProjectByProp", {prop: "nodePagetypes", data: this.project.nodePagetypes});
    },
  }
}
</script>

<style scoped></style>