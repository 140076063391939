<template>
  <div>
    <div class="node-selector row mb-0" :class="wrapperClassesComputed">
      <div v-for="(node, index) in nodes" :key="index" :class="nodeSize" class="node-wrapper mb-3">
        <div :class="nodeClassesComputed + nodeProps(node)" class="node card h-100">
          <div class="card-img-top border-bottom" @click="$emit('selectNode', node)">
            <img :src="getImg(node, 'thumb')" :alt="node.title + ' - Page Screenshot Thumbnail'" class="img-fluid cursor-pointer">
          </div>
          <div class="card-body p-2">
            <h6 class="small">
              {{ node.title }}
            </h6>
            <p class="small">
              <code>{{ nodeUrl(node) }}</code>
            </p>
          </div>
          <div v-if="!hideAll" class="card-footer p-2 pt-1">
            <NodeLinks :node="node" :exclude-items="excludeItems" :expanded="false"></NodeLinks>
          </div>
          <button v-if="accordion" class="btn btn-light btn-block" @click="accordionOpen = !accordionOpen"></button>
        </div>
        <div v-if="deletable" @click="$emit('remove', node)" class="remove-button">
          <i class="fas fa-times"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NodeLinks from "@/components/NodeLinks";

export default {
  name: 'NodeSelector',
  components: {NodeLinks},
  directives: {},
  props: {
    nodes: Array,
    selectedNodeUrl: String,
    selectedNodeUrls: Array,
    excludeItems: Array,
    wrapperClasses: String,
    nodeClasses: String,
    nodeSize: String,
    accordion: Boolean,
    deletable: Boolean
  },
  data() {
    return {
      accordionOpen: false
    }
  },
  computed: {
    hideAll() {
      return this.excludeItems[0] === 'all';
    },
    wrapperClassesComputed() {
      let classes = "";
      classes += this.accordion ? 'accordion ' : '';
      classes += this.wrapperClasses;
      return classes;
    },
    nodeClassesComputed() {
      let classes = "";
      classes += this.nodeClasses;
      return classes;
    },
  },
  watch: {},
  methods: {
    nodeUrl(node) {
      return node.url ? node.url === this.project.profile.rootUrl ? node.url : node.url.replace(this.project.profile.rootUrl, "") : "";
    },
    nodeProps(node) {
      let classes = "";
      for (let prop of ['status', 'depth']) {
        classes += " " + prop + "-" + node[prop];
      }
      if (this.selectedNodeUrl) {
        classes += this.selectedNodeUrl === node.url ? ' border-primary' : '';
      }
      if (this.selectedNodeUrls) {
        classes += this.selectedNodeUrls.includes(node.url) ? ' border-primary' : '';
      }
      return classes;
    }
  },
  mounted() {
    if (this.accordion) {
      let el = document.querySelector('.node-selector.accordion');
      el.style.height = Math.round(el.querySelector('.node-wrapper').clientHeight * 3) + "px";
    }
  }
}
</script>

<style lang="scss" scoped>
.node-selector.accordion {
  overflow-y: scroll;
}
.card-img-top {
  text-align: center;
}
.card-body {
  h6 {
    font-weight: 500;
  }
}
.badges {
  display: flex;
}
.scroll-container-v {
  height: 100%;

  &.large-v {
    height: 70vh;
  }
}
</style>