<template>
  <div>
    <div class="badges" :class="wrapperClasses">
      <a v-if="showExternal" :href="node.url" target="_blank" :title="node.title" class="badge badge-primary font-weight-normal py-1 mr-1">
        {{ expanded ? 'URL besuchen ' : '' }}<i class="fad fa-fw fa-external-link"></i>
      </a>
      <router-link v-if="showLinks"
                   :to="'/links/' + node.id"
                   :title="node.title"
                   class="badge badge-primary font-weight-normal py-1 mr-1"
      >
        {{ expanded ? 'Zu den Details ' : '' }}<i class="fad fa-fw fa-chart-network"></i>
      </router-link>
      <router-link v-if="showContents"
                   :to="'/contents/' + node.id"
                   :title="node.title"
                   class="badge badge-primary font-weight-normal py-1 mr-1"
      >
        {{ expanded ? 'Zum Content Piece ' : '' }}<i class="fad fa-fw fa-book-spells"></i>
      </router-link>
      <router-link v-if="showFindings"
                   :to="'/findings/' + node.url"
                   :title="node.title"
                   class="badge badge-primary font-weight-normal py-1 mr-1"
      >
        {{ expanded ? 'Zu den Potenzialen ' : '' }}<i class="fad fa-fw fa-radar"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodeLinks',
  components: {},
  directives: {},
  props: {
    node: Object,
    excludeItems: Array,
    expanded: Boolean,
    wrapperClasses: String
  },
  data() {
    return {}
  },
  computed: {
    showAll() {
      return !this.excludeItems;
    },
    showExternal() {
      let isValid = this.node.url !== "Platzhalter";
      return isValid && (this.showAll || !this.excludeItems.find(s => s === 'external'));
    },
    showLinks() {
      let isValid = this.project.netNodes.find(n => n.url === this.node.url);
      return isValid && (this.showAll || !this.excludeItems.find(s => s === 'links'));
    },
    showContents() {
      let isValid = this.project.externalNodes.find(n => n.url === this.node.url);
      return isValid && (this.showAll || !this.excludeItems.find(s => s === 'contents'));
    },
    showFindings() {
      return this.showAll || !this.excludeItems.find(s => s === 'findings');
    },
    showJourneys() {
      return this.showAll || !this.excludeItems.find(s => s === 'journeys');
    },
    showAudits() {
      return this.showAll || !this.excludeItems.find(s => s === 'audits');
    },
    showIdeas() {
      return this.showAll || !this.excludeItems.find(s => s === 'ideas');
    },
  },
  watch: {},
  methods: {
    nodeUrl(node) {
      return node.url === this.project.profile.rootUrl ? node.url : node.url.replace(this.project.profile.rootUrl, "")
    }
  }
}
</script>

<style lang="scss" scoped>
.card-img-top {
  text-align: center;
}
.card-body {
  h6 {
    font-weight: 500;
  }
}
.badges {
  display: flex;
}
</style>