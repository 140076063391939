<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="card p-5 mx-auto mt-4 text-center">
          <i class="fad fa-clock fa-5x text-primary"></i>
          <h3 class="mt-4">
            Lade Daten...
          </h3>
          <p>
            Wenn dein letzter Scrape (bzw. deine Seite) sehr groß war, kann es eine Weile dauern die Daten zu laden. <br>
            Bitte warte noch kurz.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentLoadingBanner',
  components: {},
  directives: {},
  props: {
    hideButtons: Boolean
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.fad {
  transition: color .5s;
}
</style>