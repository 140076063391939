<template>
  <div id="links-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link to="/links/edit" class="btn btn-primary mr-3 mb-5">
      Link Tree bearbeiten
    </router-link>
    <router-link v-if="nextApproval <= 0" to="/scraper" class="btn btn-secondary mb-5">
      Zum Website-Scraper
    </router-link>
    <button v-else class="btn btn-primary mb-5" disabled>
      Noch {{ nextApproval }} Tage bis zum nächsten Fullsite-Scrape
    </button>

    <!--<p>Todo:</p>
    <ul>
      <li>Refine Detailview of Nodes</li>
    </ul>-->

    <ContentLoadingBanner v-if="!map.nodes"></ContentLoadingBanner>
    <IntroBanner v-else-if="!map.nodes.length">
      <p>
        Du hast den ersten Schritt erledigt, beginne jetzt deine Seite nach und nach mit deiner neuen
        nutzerzentrierten Herangehensweise zu verbessern. <br>
        <br>
        Wir geben dir dafür eine Reihe weitere Tools an die Hand, um die Potentiale deiner Seite möglichst einfach zu
        entfachen. Schau dir dazu einfach folgendes Video an:
      </p>

      <p>
        Um weiter zu machen, lass uns jetzt deine Seite scrapen.
      </p>
      <div>
        <router-link class="btn btn-primary btn-lg mt-4" to="/scraper">Weiter zum Website-Scraper</router-link>
      </div>
    </IntroBanner>

    <div v-else>
      <NetworkGraph :nodes="map.nodes"
                    :links="map.links"
                    :selectedNode="selectedNode"
                    @selectNode="selectNode($event)"
      ></NetworkGraph>

      <div class="row mb-3">
        <div v-if="selectedNode" class="col-auto">
          <button class="btn btn-sm btn-link" @click="selectedNodeId = ''">
            <i class="fad fa-arrow-left"></i> Zurück zur Übersicht
          </button>
        </div>
        <div class="col">
          <input v-model="search" class="form-control form-control-sm" placeholder="Nodes suchen..." type="text">
        </div>
        <div v-if="!selectedNode" class="col-6">
          <button class="btn btn-sm btn-primary btn-block" @click="filter = filter === '' ? 'failed' : ''">
            Zeige {{ filter === '' ? 'fehlerhafte Nodes' : 'alle Nodes' }}
          </button>
        </div>
      </div>

      <div v-if="selectedNode" class="root-details">
          <NetNode :map="map"
                   :selected-node="selectedNode"
                   @selectNode="selectNode($event)"
          >
          </NetNode>
      </div>

      <div v-else-if="nodesView && nodesView.length > 1" class="link-connectedNodes">
        <h3>Dein Link Tree:</h3>
        <template v-for="depth in [0,1,2,3,4]">
          <h4 v-if="nodesView.filter(n => n.depth === depth).length > 0" :key="depth + 'h'">Level {{ depth + 1 }}</h4>

          <NodeSelector v-if="nodesView.filter(n => n.depth === depth).length > 0"
                        :nodes="nodesView.filter(n => n.depth === depth)"
                        :selected-node-url="selectedNodeUrl"
                        :exclude-items="['links']"
                        node-size="col-sm-6 col-lg-3"
                        @selectNode="selectNode($event)"
                        :key="depth"
          ></NodeSelector>

          <hr v-if="nodesView.filter(n => n.depth === depth).length > 0" :key="depth + 'd'">
        </template>
      </div>

    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import NodeSelector from "@/components/NodeSelector";
import NetworkGraph from "@/components/NetworkGraph";
import NetNode from "@/components/NetNode";
import ContentLoadingBanner from "@/components/ContentLoadingBanner";
import IntroBanner from "@/components/IntroBanner";

export default {
  name: 'Links',
  components: {
    IntroBanner,
    ContentLoadingBanner,
    NetNode,
    NetworkGraph,
    NodeSelector,
  },
  data() {
    return {
      selectedNodeId: "",
      nodesViewLimit: 50,
      search: "",
      filter: "",
      map: {},
      directories: {},
    }
  },
  computed: {
    rootUrl() {
      return this.project.profile.rootUrl;
    },
    metrics() {
      return this.project.settings.nodeMetrics;
    },
    attributes() {
      return this.project.settings.nodeAttributes;
    },
    pageTypes() {
      return this.project.settings.pageTypes;
    },
    selectedNode() {
      let node = this.project.netNodes.find(n => n.id === this.selectedNodeId) || this.project.externalNodes.find(n => n.id === this.selectedNodeId);
      return node ? this.findNode(node.url) : node;
    },
    selectedNodeUrl() {
      return this.selectedNode ? this.selectedNode.url : "";
    },
    connectedLinks() {
      return this.map.links.filter(l => l.sid === this.selectedNodeId || l.tid === this.selectedNodeId);
    },
    connectedNodes() {
      return this.map.nodes.filter(n => this.connectedLinks.find(l => l.tid === n.id));
    },
    nodesView() {
      if (this.selectedNode) {
        return this.connectedNodes;
      } else {
        return this.map.nodes.filter(node => {
          let isSearched = this.search === "";
          if (!isSearched) {
            for (let prop of ['url', 'title']) {
              if (node[prop].indexOf(this.search) >= 0) {
                isSearched = true;
              }
            }
          }
          if (this.filter === "failed") {
            isSearched = isSearched && node.status !== 200;
          }
          return isSearched;
        });
      }
    },
  },
  watch: {
  },
  methods: {
    selectNode(node) {
      this.selectedNodeId = node.id ? node.id === this.selectedNodeId ? "" : node.id : "";
      this.$forceUpdate();
    },
    selectLink(nodeId) {
      this.selectedNodeId = nodeId;
    },
    setMap() {
      let map = [];
      map = map.concat(JSON.parse(JSON.stringify(this.project.netNodes)));

      let externalNodes = JSON.parse(JSON.stringify(this.project.externalNodes));
      let contentNodes = [];
      contentNodes = contentNodes.concat(externalNodes.map(node => {
        if (node.platform) {
          node._color = this.$store.state.platforms[node.platform].color;
        }
        node._cssClass = "depth-1";
        node.depth = 1;
        node.status = 200;
        node.metrics = this.project.nodeMetrics.find(n => n.url === node.url);
        node.attributes = this.project.nodeAttributes.find(n => n.url === node.url);

        for (let link of node.links) {
          let conNode = externalNodes.find(n => n.url === link);
          if (!conNode) {
            contentNodes.push({
              id: this.generateId(),
              url: link,
              title: link,
              depth: 2,
              status: 200,
              _cssClass: "depth-2",
              _color: "#cccccc"
            });
          }
        }
        return node;
      }));

      map = map.concat(contentNodes);

      let links = JSON.parse(JSON.stringify(this.project.netLinks));

      let contentLinks = [];
      externalNodes.forEach(node => {
        let linkedNodes = [];
        if (node.nodeUrls && node.nodeUrls.length) {
          linkedNodes = node.nodeUrls.map(url => {
            let conNode = map.find(n => n.url === url);
            if (!conNode) {
              conNode = {
                id: this.generateId(),
                url,
                status: 200,
                title: url,
                depth: 2,
              }
              conNode._cssClass = "depth-1";
              map.push(conNode);
            }
            if (!contentNodes.find(n => n.url === url)) {
              contentNodes.push(conNode);
            }
            let link = {
              id: this.generateId(),
              sid: node.id,
              tid: conNode.id,
            };
            if (node.platform) {
              link._resetColor = this.$store.state.platforms[node.platform].color;
            }
            return link;
          });
        }
        if (node.links && node.links.length) {
          for (let link of node.links) {
            let conNode = externalNodes.find(n => n.url === link);
            if (conNode) {
              link = {
                id: this.generateId(),
                sid: node.id,
                tid: conNode.id
              };
            } else {
              link = {
                id: this.generateId(),
                sid: node.id,
                tid: contentNodes.find(p => p.title === link)
              };
            }
            if (node.platform) {
              link._resetColor = this.$store.state.platforms[node.platform].color;
            }
            linkedNodes.push(link);
          }
        }
        contentLinks = contentLinks.concat(linkedNodes);
      });
      links = links.concat(contentLinks);

      this.map = {
        nodes: map,
        links,
        contentNodes,
        contentLinks
      };
    },
    setDirectories() {
      let directories = {};
      this.map.nodes.forEach(node => {
        let dir;
        if (node.url.indexOf(this.rootUrl) >= 0) {
          dir = node.url.replace(this.rootUrl, "").split("/");
        } else {
          dir = [node.url];
        }
        let currentDir = directories;
        let path;
        for (path of dir) {
          if (!currentDir[path]) {
            currentDir[path] = {};
          }
          currentDir = currentDir[path];
        }
        currentDir[node.url] = node.id;
      });
      return directories;
    }
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.selectedNodeId = this.$route.params.id;
    }
  },
  mounted() {
    new Promise(resolve => {
      let interval = setInterval(() => {
        if (!this.$store.state.dataRequestPending) {
          resolve();
          clearInterval(interval);
        }
      }, 100);
    })
    .then(() => {
      this.setMap();
      this.setDirectories();
    });
  }
}
</script>