<template>
  <div class="card mb-3">
    <div class="card-header">
      <div class="row">
        <div class="col">
          <h3>{{ selectedNode.title }}</h3>
          <p><code class="h5">{{ selectedNode.url }}</code></p>
          <NodeLinks :exclude-items="['links']" :expanded="true" :node="selectedNode"></NodeLinks>
        </div>
        <div class="d-none d-md-block col-2">
          <img v-if="selectedNode.image" :src="selectedNode.image.thumb" alt="" class="img-fluid">
        </div>
      </div>
    </div>
    <div class="card-body">
      <ul class="nav nav-tabs my-3">
        <li class="nav-item">
          <a :class="view === 'base' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'base'">
            Übersicht
          </a>
        </li>
        <li class="nav-item">
          <a :class="view === 'metrics' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'metrics'">
            Infos hinzufügen <Tooltip>Erstelle hier selbst Metriken und Attribute, um deine Erkenntnisse zu speichern</Tooltip>
          </a>
        </li>
        <li class="nav-item">
          <a :class="view === 'connections' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'connections'">
            Verlinkungen
          </a>
        </li>
        <li v-if="selectedNode.image" class="nav-item">
          <a :class="view === 'image' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'image'">
            Screenshots
          </a>
        </li>
        <li v-if="selectedNode.meta" class="nav-item">
          <a :class="view === 'meta' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'meta'">
            Meta-Tags
          </a>
        </li>
        <li v-if="selectedNode.keywords" class="nav-item">
          <a :class="view === 'keywords' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'keywords'">
            Keywords
          </a>
        </li>
        <!--<li v-if="selectedNode.lemmatized_tokens" class="nav-item">
          <a :class="view === 'tokens' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'tokens'">
            Tokens
          </a>
        </li>
        <li v-if="selectedNode.lemmatized_tokens && selectedNode.keywords" class="nav-item">
          <a :class="view === 'text' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'text'">
            SEO-Text
          </a>
        </li>
        <li v-if="selectedNode.readability" class="nav-item">
          <a :class="view === 'stats' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'stats'">
            Text-Statistiken
          </a>
        </li>-->
      </ul>

      <div v-if="view === 'base'">
        <div v-if="selectedNode.status === 200">
          <div class="row">
            <div class="col-6">
              <div v-if="selectedNode.status" class="my-3">
                <h4>Details:</h4>
                <ul>
                  <li>Status code: <code>{{ selectedNode.status }}</code></li>
                  <li>Seitentyp: {{ selectedNode.pagetype }}</li>
                  <li>
                    Links: {{ connectedLinks.length }}
                    <ul>
                      <li>{{ outgoingLinks.length }} {{ outgoingLinks.length === 1 ? 'ausgehender Link' : ' ausgehende Links' }}</li>
                      <li>{{ incomingLinks.length }} {{ incomingLinks.length === 1 ? 'eingehender Link' : ' eingehende Links' }}</li>
                      <li>Zu {{ connectedNodes.length }} {{ connectedNodes.length === 1 ? 'anderer Seite bzw. Website' : 'anderen Seiten bzw. Websites' }}</li>
                    </ul>
                  </li>
                </ul>
                <!--<h5>Link Texte:</h5>
                <ul>
                  <li v-for="(link, index) in outgoingLinks" :key="index">{{ link }}</li>
                </ul>-->
                <!--<h5>Link Juice:</h5>
                <p><code>{{ selectedNode.link_juice }}</code></p>-->
              </div>

              <div v-if="selectedNode.counts" class="mt-4">
                <h4>Text Infos:</h4>
                <div class="border rounded p-3">
                  <div class="form-group">
                    <h5>Gesamtanzahl Wörter:</h5>
                    <p class="h4"><code>{{ selectedNode.counts.total_words }}</code></p>
                  </div>

                  <div class="form-group">
                    <h5>Einzigartige Wörter:</h5>
                    <p class="h4"><code>{{ selectedNode.counts.unique_words }}</code></p>
                  </div>

                  <div class="form-group">
                    <h5>Sätze:</h5>
                    <p class="h4"><code>{{ selectedNode.counts.sentences }}</code></p>
                  </div>

                  <div v-if="selectedNode.counts.difficult_words" class="form-group">
                    <h5>Schwierige / lange Wörter:</h5>
                    <p class="h4"><code>{{ selectedNode.counts.difficult_words }}</code></p>
                  </div>

                  <hr>

                  <div class="form-group">
                    <h5>Durchschnittliche Lesezeit in Minuten:</h5>
                    <p class="h4"><code>{{ estimatedReadingTime }}</code> <span class="small">(100 - 200 Wörter / Minute)</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div v-if="selectedNode.tags">
                <h5>HTML-Tags</h5>
                <div class="scroll-container-v" style="max-height: 50vh">
                  <table class="table table-striped table-sm mb-0">
                    <thead>
                    <tr>
                      <th>Tag</th>
                      <th>Count</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="tag in Object.keys(selectedNode.tags).sort()" :key="tag">
                      <td>{{ tag }}</td>
                      <td>{{ selectedNode.tags[tag] }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <p>
            Zu dieser Seite wurde ein Link gefunden, doch sie wurde nicht gescraped. Du kannst sie manuell hinzufügen.
          </p>
          <router-link to="/contents/add" class="btn btn-primary">
            Externen Content hinzufügen <i class="fal fa-arrow-right"></i>
          </router-link>
        </div>
      </div>
      <div v-else-if="view === 'metrics'">
        <NodeEditor :node="selectedNode"></NodeEditor>
      </div>
      <div v-else-if="view === 'image'">
        <ul class="nav nav-tabs my-3">
          <li class="nav-item">
            <a :class="imageView === 'thumb' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="imageView = 'thumb'">
              Vorschau <i v-if="selectedNode.image['thumb']" class="fad fa-check-circle text-primary"></i>
            </a>
          </li>
          <li class="nav-item">
            <a :class="imageView === 'desktop' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="imageView = 'desktop'">
              Desktop <i v-if="selectedNode.image['desktop']" class="fad fa-check-circle text-primary"></i>
            </a>
          </li>
          <li class="nav-item">
            <a :class="imageView === 'tablet' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="imageView = 'tablet'">
              Tablet <i v-if="selectedNode.image['tablet']" class="fad fa-check-circle text-primary"></i>
            </a>
          </li>
          <li class="nav-item">
            <a :class="imageView === 'mobile' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="imageView = 'mobile'">
              Mobile <i v-if="selectedNode.image['mobile']" class="fad fa-check-circle text-primary"></i>
            </a>
          </li>
        </ul>
        <div v-for="(size, index) in sizes" :key="'a' + index">
          <div v-show="size.prop === imageView && selectedNode.image[imageView]">
            <div class="node-screen-viewer">
              <img :src="selectedNode.image[imageView]" alt="">
            </div>
          </div>
        </div>
        <div v-if="!selectedNode.image[imageView]">
          <router-link :to="'/contents/' + selectedNode.id" class="btn btn-primary mt-3">
            Bild hinzufügen <i class="fal fa-arrow-right"></i>
          </router-link>
        </div>
      </div>
      <div v-else-if="view === 'connections'">
        <div class="row">
          <div class="col-md-6">
            <h4>Netzwerkgraph:</h4>
            <NetworkGraph :links="connectedLinks" :nodes="connectedNodes" :reduced="true" @selectNode="selectNode($event)"></NetworkGraph>
          </div>
          <div class="col-md-6">
            <h4>Verlinkte Seiten:</h4>
            <NodeSelector :nodes="connectedNodes"
                          :selected-node-url="selectedNode.url"
                          :exclude-items="['links']"
                          node-size="col-md-4 col-lg-6"
                          @selectNode="selectNode($event)"
            ></NodeSelector>
          </div>
        </div>
      </div>
      <div v-else-if="view === 'text'">
        <p class="text-muted">This is how a search engine might interpret your page content.</p>
        <div class="row align-items-center">
          <div class="col-auto"><label class="mb-1" for="text-size-factor">Text Size Factor</label></div>
          <div class="col">
            <input id="text-size-factor" v-model="textSizeFactor" class="form-control" max="0.5"
                   min="0.1"
                   step="0.01"
                   type="range"
            >
          </div>
        </div>
        <hr>
        <p>
          <span v-for="(word, index) in selectedNode.lemmatized_tokens" :key="index"
                :style="'font-size:' + (Math.pow(selectedNode.keywords[word], textSizeFactor)) + 'rem'"
          >
            {{ word }}
          </span>
        </p>
      </div>
      <div v-else-if="view === 'tokens'">
        <span v-for="(token, index) in selectedNode.lemmatized_tokens" :key="index" class="badge badge-light m-2">{{ token }}</span>
      </div>
      <div v-else-if="view === 'meta'">
        <h4>Meta-Tags</h4>
        <table class="table table-sm">
          <thead>
          <tr>
            <th>Meta-Tag</th>
            <th>Auf der Seite</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(tag, index) in Object.keys(selectedNode.meta)" :key="index">
            <td>{{ tag }}</td>
            <td>{{ selectedNode.meta[tag] }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="view === 'keywords'">
        <h4>Keywords</h4>
        <!--<p>
          This list contains the keywords of your page just like a search engine would read them. The text on your
          website is in most cases processed to "tokens" by these engines. Tokens are words of a text, stripped by
          their grammatical forms and endings that usually make them "human-readable". <br>
          This way, computers are able to make better calculations and assumptions about topics of your
          webpages more precise. <br>
          <router-link to="/blog/keyword-processing">Learn More</router-link>
        </p>-->
        <ol class="list-group">
          <li v-for="(keyword, index) in Object.keys(selectedNode.keywords).reverse()
                            .slice(0, viewAllKeywords ? Object.keys(selectedNode.keywords).length : 30)"
              :key="index"
              class="list-group-item"
          >
            <p :style="'font-size:' + (1 + 1.5/(index+1)) + 'rem'">{{ index + 1 }}. <code>"{{ keyword }}"</code></p>
            <table class="table table-sm">
              <tbody>
              <tr>
                <td>Text Occurrences:</td>
                <td>{{ selectedNode.keywords[keyword] }}</td>
              </tr>
              <tr>
                <td>Term Frequency:</td>
                <td>{{ (selectedNode.keywords[keyword] / selectedNode.counts.total_words * 100).toFixed(3) }} %</td>
              </tr>
              </tbody>
            </table>
          </li>
        </ol>
        <button v-if="!viewAllKeywords" class="btn btn-light" @click="viewAllKeywords = true">View all</button>
      </div>
      <div v-else-if="view === 'stats'">
        <ComingSoonBanner :hide-buttons="true"></ComingSoonBanner>
        <!--<h4>Statistik</h4>
        <table v-if="selectedNode.readability['text_standard']" class="table table-striped">
          <thead>
          <tr>
            <th>Text Standard (Average):</th>
            <th>{{ selectedNode.readability['text_standard'] }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(stat, index) in Object.keys(selectedNode.readability).slice(1)" :key="index">
            <td>{{ stat }}:</td>
            <td>{{ selectedNode.readability[stat] }}</td>
          </tr>
          </tbody>
        </table>
        <p v-else>
          Textstatistiken sind zur Zeit leider nur für englisch oder deutsch gescrapete Seiten verfügbar.
        </p>-->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NodeLinks from "@/components/NodeLinks";
import NetworkGraph from "@/components/NetworkGraph";
import ComingSoonBanner from "@/components/ComingSoonBanner";
import Tooltip from "@/components/Tooltip";
import NodeEditor from "@/components/NodeEditor";
import NodeSelector from "@/components/NodeSelector";

export default {
  name: 'netNode',
  props: {
    selectedNode: Object,
    map: Object
  },
  components: {
    NodeSelector,
    NodeEditor,
    Tooltip,
    ComingSoonBanner,
    NetworkGraph,
    NodeLinks
  },
  data() {
    return {
      view: 'base',
      imageView: 'desktop',
      sizes: [
        { title: 'Vorschau', prop: 'thumb' },
        { title: 'Desktop', prop: 'desktop' },
        { title: 'Tablet', prop: 'tablet' },
        { title: 'Mobile', prop: 'mobile' },
      ],
      textSizeFactor: 0.35,
      viewAllKeywords: false,
      connectedLinks: [],
      outgoingLinks: [],
      incomingLinks: [],
      connectedNodes: [],
    }
  },
  computed: {
    estimatedReadingTime() {
      let counts = this.selectedNode.counts;
      let difficultWords = counts.difficult_words ? counts.difficult_words : 0;
      let readingTime = (counts.total_words - difficultWords) / 200 + difficultWords / 150;
      let imageTime = this.selectedNode.tags.img * (12 / 60);

      return Math.round(readingTime + imageTime);
    },
    pagetypes() {
      return this.project.settings.nodePagetypes || [];
    },
    metrics() {
      return this.project.settings.nodeMetrics || [];
    },
    attributes() {
      return this.project.settings.nodeAttributes || [];
    },
  },
  methods: {
    selectNode($event) {
      this.$emit('selectNode', $event);
    }
  },
  beforeMount() {
  },
  beforeUpdate() {
    this.connectedLinks = this.map.links.filter(l => l.sid === this.selectedNode.id || l.tid === this.selectedNode.id);
    this.connectedNodes = this.map.nodes.filter(n => this.connectedLinks.find(l => l.tid === n.id || l.sid === n.id));
    this.outgoingLinks = this.connectedLinks.filter(l => l.sid === this.selectedNode.id);
    this.incomingLinks = this.connectedLinks.filter(l => l.tid === this.selectedNode.id);
  },
}
</script>

<style lang="scss">
.preview-wrapper {
  width: 100%;

  .preview {
    margin: 0 auto;
  }

  iframe.preview {
    width: 100%;
    height: 100vh;
  }
}

.node-screen-viewer {
  height: 80vh;
  overflow-y: scroll;
  text-align: center;

  img {
    width: auto;
    max-width: 100%;
    margin: 0 auto;
  }
}
</style>
