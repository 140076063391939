<template>
  <span class="custom-tooltip">
    <span class="tooltip-trigger">
      <i class="fad fa-info-circle"></i>
    </span>
    <span class="tooltip-content">
      <slot></slot>
    </span>
  </span>
</template>

<script>
export default {
  name: 'Tooltip',
  components: {},
  directives: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {}
}
</script>

<style lang="scss" scoped>

.custom-tooltip {
  display: inline-block;
  position: relative;

  .tooltip-trigger {
    display: inline-block;
    color: var(--primary);
  }
  .tooltip-content {
    display: none;
    pointer-events: none;
    position: absolute;
    bottom: 100%;
    left: -50%;
    width: 300px;
    background: var(--light);
    border-radius: 4px;
    box-shadow: 0 .2rem .3rem 0 rgba(0,0,0,.2);
    padding: .2rem .5rem;
    z-index: 3;
    border: 1px solid rgba(0,0,0,.1);
    color: var(--dark);
  }

  &:hover {
    .tooltip-trigger {
      color: var(--primary-dark);
    }
    .tooltip-content {
      display: block;
    }
  }
}
</style>